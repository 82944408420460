.grad-form {
  margin-top: 15px;
  margin-bottom: 40px;
  max-width: 800px;
}
.grad-form-group {
  position: absolute;
  left: 0;
  top: 0;
}
.helper {
  color: #868686;
  font-weight: 300;
  margin-left: 4px;
}
.helper-asterisk {
  margin-left: 1px;
}
.helper-asterisk-red {
  color: #a94442;
}
textarea {
  resize: none;
}
.isActive {
  display: block;
  float: left;
  margin-right: 6px;
}

/* --- Image & Update Button ------------------ */
/* --- See additional styling in Search.css --- */
.form-thumbnail {
  position: relative;
  display: block;
  float: left;
}
.form-thumbnail img {
  position: relative;
  float: left;
  border: 1px solid #e2e2e2;
}
.choose-btn {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #8ec957;
  border: 1px solid #8ec957;
  opacity: 0;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.choose-btn h3,
.missing-btn h3 {
  text-align: center;
  color: #fff;
  font-size: 22px;
  padding-top: 6px;
}
.form-thumbnail:hover .choose-btn {
  opacity: 1;
}
.missing-btn {
  position: relative;
  float: left;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #e1e0e0;
  border: 1px solid #e1e0e0;
}
#image {
  position: relative;
  background: #8ec957;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  width: 100px;
  height: 100px;
  font-size: 24px;
  border-radius: 50%;
}

/* --- Resume & Update Button --------- */
.form-resume {
  position: relative;
  float: left;
  margin-bottom: 15px;
  margin-left: 20px;
}
.form-resume img {
  position: relative;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
}
.form-resume:hover .choose-btn {
  opacity: 1;
}
#resume {
  position: relative;
  background: #8ec957;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  width: 100px;
  height: 100px;
  font-size: 24px;
  border-radius: 50%;
}