/**********************************/
/***** HEADERS ********************/
/**********************************/
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.grad-header {
  position: relative;
  max-width: 800px;
}
.header-wrap {
  background: #1c1c1c;
  padding: 13px 0;
  z-index: 1;
}
.search-headline {
  float: left;
  margin-bottom: 4px;
}
header h1 {
  float: left;
  color: white;
  font-size: 30px;
  margin: 0;
}
.search-input input::-webkit-input-placeholder, /* WebKit, Blink, Edge */
.search-input input:-ms-input-placeholder, /* Internet Explorer 10-11 */
.search-input input::-ms-input-placeholder, /* Microsoft Edge */
.search-input input::placeholder {
  color: #868686;
}
.search-input input:-moz-placeholder, /* Mozilla Firefox 4 to 18 */
.search-input input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #868686;
  opacity: 1;
}
.search-input input {
  color: #fff;
  background: #383838;
  border-color: #383838;
  margin: 0 0 7px;
}
.search-input .form-group {
  margin-bottom: 0;
}
.search-icon {
  position: absolute;
  color: #868686;
  top: 45px;
  right: 23px;
}

@media (min-width: 480px) {
  .header-wrap {
    padding: 20px 0;
  }
  header h1 {
    font-size: 36px;
  }
  .search-icon {
    top: 50px;
  }
}

/**************************************/
/***** PROFILE CARDS ******************/
/**************************************/

/* ------ Bootstrap Overrides ------- */
.media-body {
  padding-left: 10px;
  padding-top: 10px;
}
/* ---------------------------------- */
/* enable absolute positioning */
.profile-directory {
  max-width: 800px;
  margin: auto;
  position: relative;
  padding: 0 10px;
}
.profile-directory h1 {
  font-family: Roboto Condensed;
  font-weight: 700;
  text-align: center;
  margin: 35px auto 10px;
}
.card {
  border-top: 1px solid #d0d0d0;
  padding: 20px 0 10px;
  margin-bottom: 10px;
}
.card:first-child {
  border-top: none;
}
.profile-thumbnail-wrap {
  width: 100px;
  height: 100px;
  text-indent: -50%;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
}
.profile-thumbnail {
  margin-bottom: 15px;
}
.profile-thumbnail img {
  object-fit: cover;
  box-shadow: none;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
}
.media-left > a img:hover {
  opacity: 0.7;
}
.media-body h4 {
  font-size: 23px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 10px;
}
.media-body h4 a {
  font-size: 23px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 10px;
  color: #383838;
}
.media-body h4 a:hover {
  color: #00b5e0;
  text-decoration: none;
}
