.App {
  text-align: center;
}
.wrapper {
    position: relative; /* necessary to push footer to bottom */
    height: auto; /* necessary to push footer to bottom */
    min-height: 100%; /* fills the space when content is less than 100% height of viewport */
    width: 100%;
    margin: 0;
    padding-bottom: 80px; /* creates a space for footer to overlap .wrapper. MUST match footer properties height & margin-top (Footer.css) */
}

@media (min-width: 992px) {
  .wrapper {
    padding-bottom: 64px; /* creates a space for footer to overlap .wrapper */
  }
}

/*****************************************/
/****** COLORS ***************************/
/*****************************************/
.acc-primary {
  color: #00b5e0;
}
.acc-primary-bg {
  background: #00b5e0;
}
.acc-secondary {
  color: #8ec957;
}
.acc-secondary-bg {
  background: #8ec957;
}
.acc-admin {
  color: #eeb50e;
}
.acc-admin-bg {
  background: #eeb50e;
}

/*****************************************/
/****** BUTTONS **************************/
/*****************************************/

/* Rectangular Buttons */
.acc-btn {
  padding: 8px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 1 !important;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.acc-btn-primary {
  color: #fff;
  border: 1px solid #00b5e0;
  background: #00b5e0;
}
.acc-btn-primary:hover {
  color: #00b5e0;
  border: 1px solid #00b5e0;
  background: transparent;
}
.acc-btn-secondary {
  color: #fff;
  border: 1px solid #8ec957;
  background: #8ec957;
}
.acc-btn-secondary:hover {
  color: #8ec957;
  border: 1px solid #8ec957;
  background: transparent;
}
/* Oval Buttons */
.grad-btn {
  padding: 8px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 1 !important;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
/* Primary */
.grad-btn-primary {
  color: #00b5e0;
  border: 1px solid #00b5e0;
  background: transparent;
}
.grad-btn-primary:hover {
  color: #fff;
  border: 1px solid #00b5e0;
  background: #00b5e0;
}
.grad-btn-primary:focus {
  color: #00b5e0;
  border: 1px solid #00b5e0;
  background: transparent;
}
/* Secondary */
.grad-btn-secondary {
  color: #8ec957;
  border: 1px solid #8ec957;
  background: transparent;
}
.grad-btn-secondary:hover {
  color: #fff;
  border: 1px solid #8ec957;
  background: #8ec957;
}
.grad-btn-secondary:focus {
  color: #8ec957;
  border: 1px solid #8ec957;
  background: transparent;
}
/* Admin */
.grad-btn-admin {
  color: #eeb50e;
  border: 1px solid #eeb50e;
  background: transparent;
}
.grad-btn-admin:hover {
  color: #fff;
  border: 1px solid #eeb50e;
  background: #eeb50e;
}
/* Admin Submit */
.grad-btn-admin-submit {
  color: #fff;
  border: 1px solid #eeb50e;
  background: #eeb50e;
  margin-top: 5px;
}
.grad-btn-admin-submit:hover {
  color: #eeb50e;
  border: 1px solid #eeb50e;
  background: transparent;
}
/* Admin isActive */
.grad-btn-admin-active {
  color: #eeb50e;
  border: 1px solid #eeb50e;
  background: #fff;
  width: 90px;
  padding-left: 0;
  padding-right: 0;
}
.grad-btn-admin-active:hover {
  color: #fff;
  border: 1px solid #adadad;
  background: #adadad;
}
.grad-btn-admin-active:focus {
  color: #eeb50e;
  border: 1px solid #eeb50e;
  background: #fff;
  outline: 5px auto #eeb50e;
  outline-offset: -2px;
}
.grad-btn-admin-inactive {
  color: #adadad;
  border: 1px solid #adadad;
  background: #fff;
  width: 83px;
  padding-left: 0;
  padding-right: 0;
}
.grad-btn-admin-inactive:hover {
  color: #fff;
  border: 1px solid #eeb50e;
  background: #eeb50e;
}
.grad-btn-admin-inactive:focus {
  color: #adadad;
  border: 1px solid #adadad;
  background: #fff;
  outline: 5px auto #adadad;
  outline-offset: -2px;
}
.grad-btn-admin-active:hover span,
.grad-btn-admin-inactive:hover span {
  display: none;
}
.grad-btn-admin-active:hover:after {
  content: "Deactivate";
}
.grad-btn-admin-inactive:hover:after {
  content: "Activate";
}

/* Add */
.grad-btn.add-btn {
  font-size: 22px;
  margin: 3px 12px 0;
  padding: 1px 6px;
}

@media (min-width: 480px) {
  .grad-btn.add-btn {
    font-size: 26px;
    margin-left: 12px;
    margin-top: 3px;
    padding: 1px 7px;
  }
}

/*****************************************/
/****** SEARCH RESULTS INFO **************/
/*****************************************/
.links {
  margin-right: 10px;
  padding: 8px 8px;
}
.links i {
  color: #00b5e0;
}
.links:hover i {
  color: #fff;
}
.skills {
  color: #00b5e0;
  font-size: 600;
  word-spacing: 2px;
}

/*****************************************/
/****** MISC BOOTSTRAP OVERRIDES *********/
/*****************************************/
.btn-primary {
  background: #00b5e0;
}
.btn-link {
  color: #00b5e0;
  font-weight: 500;
  text-align: left;
  padding: 0;
}
.btn-link:hover {
  color: #00b5e0;
}
.acc-navbar {
  background-color: #383838;
  border-radius: 0;
  margin-bottom: 0;
}
.acc-navbar ul.navbar-nav {
  margin: 0 -15px;
}
.acc-header {
  position: relative;
}
.acc-brand {
  padding: 0 15px;
  height: auto;
  line-height: 94px;
}
.acc-brand img {
  position: relative;
  display: inline-block;
  width: 225px;
}
.navbar-inverse .navbar-nav>li.acc-link > a {
  color: #fff;
  font-family: "Roboto Condensed", "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 900;
}
.navbar-inverse .navbar-nav>li.acc-link:hover a {
  color: #00b5e0;
}
.navbar-inverse .navbar-nav>.acc-link.active > a {
  color: #00b5e0;
  background-color: transparent;
}
.navbar-toggle {
  background-color: #00b5e0;
}
.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
  background-color: #00b5e0;
}
.nav>li.nav-search-word {
  display: none;
}
.nav>li.nav-search-icon {
  display: block;
}
@media (min-width: 480px) {
  .acc-brand img {
    width: 365px;
  }
}
@media (min-width: 768px) {
  .navbar-nav li a {
    line-height: 94px;
    padding: 0 15px;
  }
  .nav>li.nav-search-word {
    display: block;
  }
  .nav>li.nav-search-icon {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .navbar-toggle {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      margin: 0 15px 0;
  }
  .nav>li.nav-search-icon {
    display: block;
    float: right;
  }
  .acc-header.non-admin {
    float: left;
  }
  .nav>li.nav-search-icon a {
    line-height: 91px;
    padding: 3px 15px 0;
  }
}
.clearfix {
  clear: both;
}
footer {
  position: absolute;
  bottom: 0;
  margin-top: -80px;  /* Overlaps the .wrapper padding-bottom by amount entered - MUST be fixed amount. Need to adjust with media queries to ensure responsiveness. */
  height: 80px;  /* MUST match margin-top & .wrapper padding-bottom (App.css). */
  width: 100%;
  background: #000;
  text-align: center;
}
footer p, footer a {
  color: #fff;
  font-family: "Roboto Condensed", "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 900;
  margin-top: 22px;
}
footer p {
  display: block;
  float: none;
  clear: both;
  margin: 22px auto 0;
}
footer a {
  text-decoration: none;
  padding: 8px;
}
footer a:hover {
  text-decoration: none;
  color: #00b5e0;
}
footer li {
  float: left;
  list-style-type: none;
}
footer ul {
  display: inline-block;
  float: none;
  margin: 0 auto;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}
@media (min-width: 480px) {
  footer a {
    padding: 10px;
  }
}
@media (min-width: 992px) {
  footer {
    position: absolute;  /* This and following line position footer at bottom of page. */
    bottom: 0;
    margin-top: -64px;  /* Overlaps the .wrapper padding-bottom by amount entered - MUST be fixed amount. */
    height: 64px;  /* MUST match margin-top & .wrapper padding-bottom (App.css). */
    width: 100%;
    background: #000;
    text-align: center;
  }
  footer p, footer a {
    font-size: 14px;
  }
  footer ul {
    float: right;
    margin-top: 22px;
  }
  footer p {
    float: left;
  }
}
.login {
  max-width: 480px;
  margin: auto;
}
.login header {
  margin: 35px auto 20px;
  padding: 0;
}
.login-input {
  margin: 10px 0;
}
.login .form-group {
  margin-bottom: 0;
}
.login-btn {
  margin: 10px 0;
  width: 100%;
}
.login .panel {
  margin-top: 4em;
  margin-bottom: 4em;
  border: 1px solid #ccc;
}
.login-error {
  margin-bottom: 0;
}

/* --- Other Components -------*/
.grad-error {
  margin: 40px auto;
  max-width: 800px;
}

.pageloader {
  width: 100%;
}

.pageloader img {
  display: block;
  margin: 80px auto;
}

/**********************************/
/***** HEADERS ********************/
/**********************************/
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.grad-header {
  position: relative;
  max-width: 800px;
}
.header-wrap {
  background: #1c1c1c;
  padding: 13px 0;
  z-index: 1;
}
.search-headline {
  float: left;
  margin-bottom: 4px;
}
header h1 {
  float: left;
  color: white;
  font-size: 30px;
  margin: 0;
}
.search-input input::-webkit-input-placeholder, 
.search-input input:-ms-input-placeholder, 
.search-input input::-ms-input-placeholder, 
.search-input input::-webkit-input-placeholder {
  color: #868686;
}
.search-input input::-webkit-input-placeholder, /* WebKit, Blink, Edge */
.search-input input:-ms-input-placeholder, /* Internet Explorer 10-11 */
.search-input input::-ms-input-placeholder, /* Microsoft Edge */
.search-input input::placeholder {
  color: #868686;
}
.search-input input:-moz-placeholder, /* Mozilla Firefox 4 to 18 */
.search-input input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #868686;
  opacity: 1;
}
.search-input input {
  color: #fff;
  background: #383838;
  border-color: #383838;
  margin: 0 0 7px;
}
.search-input .form-group {
  margin-bottom: 0;
}
.search-icon {
  position: absolute;
  color: #868686;
  top: 45px;
  right: 23px;
}

@media (min-width: 480px) {
  .header-wrap {
    padding: 20px 0;
  }
  header h1 {
    font-size: 36px;
  }
  .search-icon {
    top: 50px;
  }
}

/**************************************/
/***** PROFILE CARDS ******************/
/**************************************/

/* ------ Bootstrap Overrides ------- */
.media-body {
  padding-left: 10px;
  padding-top: 10px;
}
/* ---------------------------------- */
/* enable absolute positioning */
.profile-directory {
  max-width: 800px;
  margin: auto;
  position: relative;
  padding: 0 10px;
}
.profile-directory h1 {
  font-family: Roboto Condensed;
  font-weight: 700;
  text-align: center;
  margin: 35px auto 10px;
}
.card {
  border-top: 1px solid #d0d0d0;
  padding: 20px 0 10px;
  margin-bottom: 10px;
}
.card:first-child {
  border-top: none;
}
.profile-thumbnail-wrap {
  width: 100px;
  height: 100px;
  text-indent: -50%;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
}
.profile-thumbnail {
  margin-bottom: 15px;
}
.profile-thumbnail img {
  object-fit: cover;
  box-shadow: none;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
}
.media-left > a img:hover {
  opacity: 0.7;
}
.media-body h4 {
  font-size: 23px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 10px;
}
.media-body h4 a {
  font-size: 23px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 10px;
  color: #383838;
}
.media-body h4 a:hover {
  color: #00b5e0;
  text-decoration: none;
}

.modal-header {
  color: #fff;
  background: #00b5e0;
}
.grad-form {
  margin-top: 15px;
  margin-bottom: 40px;
  max-width: 800px;
}
.grad-form-group {
  position: absolute;
  left: 0;
  top: 0;
}
.helper {
  color: #868686;
  font-weight: 300;
  margin-left: 4px;
}
.helper-asterisk {
  margin-left: 1px;
}
.helper-asterisk-red {
  color: #a94442;
}
textarea {
  resize: none;
}
.isActive {
  display: block;
  float: left;
  margin-right: 6px;
}

/* --- Image & Update Button ------------------ */
/* --- See additional styling in Search.css --- */
.form-thumbnail {
  position: relative;
  display: block;
  float: left;
}
.form-thumbnail img {
  position: relative;
  float: left;
  border: 1px solid #e2e2e2;
}
.choose-btn {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #8ec957;
  border: 1px solid #8ec957;
  opacity: 0;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.choose-btn h3,
.missing-btn h3 {
  text-align: center;
  color: #fff;
  font-size: 22px;
  padding-top: 6px;
}
.form-thumbnail:hover .choose-btn {
  opacity: 1;
}
.missing-btn {
  position: relative;
  float: left;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #e1e0e0;
  border: 1px solid #e1e0e0;
}
#image {
  position: relative;
  background: #8ec957;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  width: 100px;
  height: 100px;
  font-size: 24px;
  border-radius: 50%;
}

/* --- Resume & Update Button --------- */
.form-resume {
  position: relative;
  float: left;
  margin-bottom: 15px;
  margin-left: 20px;
}
.form-resume img {
  position: relative;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
}
.form-resume:hover .choose-btn {
  opacity: 1;
}
#resume {
  position: relative;
  background: #8ec957;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  width: 100px;
  height: 100px;
  font-size: 24px;
  border-radius: 50%;
}
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  position: relative;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  color: #868686;
  line-height: 22px;
}
h1, h2, h3, h4, h5, h6 {
  font-family: Roboto Condensed;
  font-weight: 700;
}
a {
  color: #00b5e0;
  font-weight: 500;
}
a:hover {
  color: #00b5e0;
}
.clearfix {
  clear: both;
}
.inline-block {
  display: inline-block;
}
