body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  position: relative;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  color: #868686;
  line-height: 22px;
}
h1, h2, h3, h4, h5, h6 {
  font-family: Roboto Condensed;
  font-weight: 700;
}
a {
  color: #00b5e0;
  font-weight: 500;
}
a:hover {
  color: #00b5e0;
}
.clearfix {
  clear: both;
}
.inline-block {
  display: inline-block;
}