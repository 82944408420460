.login {
  max-width: 480px;
  margin: auto;
}
.login header {
  margin: 35px auto 20px;
  padding: 0;
}
.login-input {
  margin: 10px 0;
}
.login .form-group {
  margin-bottom: 0;
}
.login-btn {
  margin: 10px 0;
  width: 100%;
}
.login .panel {
  margin-top: 4em;
  margin-bottom: 4em;
  border: 1px solid #ccc;
}
.login-error {
  margin-bottom: 0;
}

/* --- Other Components -------*/
.grad-error {
  margin: 40px auto;
  max-width: 800px;
}
